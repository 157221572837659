<template>
  <v-container fluid>
    <WaitingDialog :show="showWaiting"></WaitingDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('container.custom-deliver-fee')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:item.total_cubic_feet="{item}">{{ fixedFloat(item.total_cubic_feet,2,0) }}</template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="viewItem(item)"
              >mdi-pencil</v-icon>
            </template>
            <span>{{ $t("報關單價設定") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import BaseTable from "./BaseTable.vue";
import WaitingDialog from "../dialogs/WaitingDialog";
import { action_items,container_status } from "@/definition.js";
export default {
  data: (vm) => ({
    url: "/container/container/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.number"),
        value: "container_number",
      },
      // {
      //   sortable: true,
      //   text: vm.$i18n.t("container.order_number"),
      //   value: "order_number",
      // },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_box_amount"),
        value: "total_box_amount",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_cubic_feet"),
        value: "total_cubic_feet",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.stuffing-date"),
        value: "stuffing_date",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.cutoff-date"),
        value: "cutoff_date",
      },
      // {
      //   sortable: false,
      //   text: vm.$i18n.t("export"),
      //   value: "export",
      // },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    ],
    action_items: action_items,
    container_status: container_status,
    showWaiting: false,
    errorTitle: '',
    errorContent: ''
  }),
  mixins: [BaseTable],
  components: {
    WaitingDialog,
  },
  methods: {
    viewItem(item) {
      console.log("item", item);
      this.$router.push({
        name: "CustomDeliverFeeDetail",
        query: {
          container: item.id,
          name: item.container_number,
          mode: item.status >= 3 ? 2 : 1,
        },
      });
    },
    get_apply_custom_error(item) {
      if (item.apply_custom_data) {
        const apply_custom_data = JSON.parse(item.apply_custom_data);
        return apply_custom_data["error_amount"];
      }
      return "-";
    },
  },
  mounted() {
    this.getParams['status__lt'] = this.container_status.disable
  },
};
</script>
